/*
og
#264653 dark blueish
#2A9D8F teal
#F9623C fire orange
*/
/*brand
GREEN
HEX: #7D9158
RGB: 125 / 145 / 88
CMYK: 54 / 28 / 78 / 7
 
BLUE
HEX: #5D82A0
RGB: 93 / 130 / 160
CMYK: 68 / 42 / 24 / 2
 
DARK BLUE
HEX: 5A7082
RGB: 90 / 112 / 130
CMYK: 69 / 49 / 37 / 10
 
ORANGE
HEX: #DA7E39
RGB: 218 / 126 / 57
CMYK: 4 / 56 / 85 / 8
*/

div#root {
  height: 100vh !important;
}

.app-primary-color {
  background-color: #5a7082 !important;
  color: #ffffff !important;
  border-color: #5a7082 !important;
}

.app-primary-color:focus {
  border-color: #5a7082 !important;
  box-shadow: 0 0 0 0.25rem rgba(90, 112, 130, 0.5) !important;
}

.app-primary-color-inverse {
  background-color: #ffffff !important;
  color: #5a7082 !important;
  border-color: #5a7082 !important;
}

.app-primary-alt-color {
  background-color: #5d82a0 !important;
  color: #ffffff !important;
  border-color: #5d82a0 !important;
}

.app-primary-alt-color:focus {
  border-color: #5d82a0 !important;
  box-shadow: 0 0 0 0.25rem rgba(93, 130, 160, 0.5) !important;
}

.app-primary-alt-color-inverse {
  background-color: #ffffff !important;
  color: #5d82a0 !important;
  border-color: #5d82a0 !important;
}

.app-secondary-color {
  background-color: #7d9158 !important;
  color: #ffffff !important;
  border-color: #7d9158 !important;
}

.app-secondary-color:focus {
  border-color: #7d9158 !important;
  box-shadow: 0 0 0 0.25rem rgba(125, 145, 88, 0.5) !important;
}

.app-secondary-color-inverse {
  background-color: #ffffff !important;
  color: #7d9158 !important;
  border-color: #7d9158 !important;
}

.app-secondary-color-inverse:focus {
  border-color: #7d9158 !important;
  box-shadow: 0 0 0 0.25rem rgba(125, 145, 88, 0.5) !important;
}

.app-tertiary-color {
  background-color: #da7e39 !important;
  color: #ffffff !important;
  border-color: #da7e39 !important;
}

.app-tertiary-color:focus {
  border-color: #da7e39 !important;
  box-shadow: 0 0 0 0.25rem rgba(218, 126, 57, 0.5) !important;
}

.app-tertiary-color-inverse {
  background-color: #ffffff !important;
  color: #da7e39 !important;
  border-color: #da7e39 !important;
}

.app-danger-color {
  background-color: #dc664b !important;
  color: #ffffff !important;
  border-color: #dc664b !important;
}

.app-danger-color:focus {
  border-color: #dc664b !important;
  box-shadow: 0 0 0 0.25rem rgba(220, 102, 75, 0.5) !important;
}

.app-danger-color-inverse {
  background-color: #ffffff !important;
  color: #dc664b !important;
  border-color: #dc664b !important;
}

.app-light-color {
  background-color: #f6f3ee !important;
  /* color: #ffffff !important;
  border-color: #F6F3EE !important; */
}

.app-light-gray-text {
  color: lightgray;
}

.app-header-text {
  color: #5a7082 !important;
}

.app-orange-text {
  color: #da7e39;
}

.app-green-text {
  color: #7d9158;
}

.app-danger-text {
  color: #dc664b !important;
}

.alert-link:hover {
  color: #da7e39 !important;
}

.button-menu:focus {
  border-color: transparent !important;
  border: none;
  box-shadow: none !important;
}

.button-divider {
  height: 50px;
}

.button-none {
  border-color: transparent !important;
  box-shadow: none !important;
}

.button-filter {
  width: 220px;
  height: 40px;
}

.button-icon-filter {
  width: 45px;
  z-index: 0 !important;

  /* height: 40px; */
}

.button-filter-clicked {
  color: #da7e39 !important;
}

.button-nav {
  width: clamp(150px, 100%, 180px) !important;
  min-height: 50px;
  /* background-color: red !important; */
  /* margin: 20px; */
}

.button-nav:focus {
  border-color: #da7e39 !important;
  box-shadow: 0 0 0 0.25rem rgba(218, 126, 57, 0.5) !important;
}

@media (max-width: 1400px) {
  .button-nav {
    width: clamp(150px, 100%, 180px) !important;
    min-height: 50px;
    /* background-color: blue !important; */
    /* margin: 20px; */
  }
}

@media (max-width: 1200px) {
  .button-nav {
    width: clamp(120px, 100%, 150px) !important;
    min-height: 50px;
    /* background-color: orange !important; */
    /* margin: 20px; */
  }
}

@media (max-width: 992px) {
  .button-nav {
    width: clamp(120px, 100%, 120px) !important;
    min-height: 50px;
    /* background-color: green !important; */
    /* margin: 20px; */
  }
}

@media (max-width: 768px) {
  .button-nav {
    width: clamp(100px, 100%, 100px) !important;
    min-height: 50px;
    /* background-color: purple !important; */
    /* margin: 20px; */
  }
}

.custom-col {
  min-width: 80px !important;
  max-width: 180px !important;
}

@media (max-width: 992px) {
  .custom-container {
    max-width: 800px !important;
  }
}

@media (max-width: 768px) {
  .custom-container {
    max-width: 700px !important;
    min-width: 700px !important;
  }
}

.button-small {
  width: 100px;
}

.button-smaller {
  width: 30px;
  height: 30px;
}

.button-square-small {
  width: 40px;
  height: 40px;
}

.button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  filter: saturate(110%) contrast(110%);
  font-weight: 400;
}

.button-today-filter {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  filter: saturate(110%) contrast(110%);
  font-weight: 400;
}

.card-assessment-sticky {
  top: 100px !important;
}

.card-assessment-menu-open-sticky {
  top: 200px !important;
}

.card-note-sticky {
  top: 80px !important;
}

.card-note-menu-open-sticky {
  top: 180px !important;
}

.card-body {
  white-space: pre-line;
}

.card-container {
  display: contents;
}

.card-cases {
  width: 220px;
  height: 160px;
  /* margin: 20px; */
  -webkit-box-shadow: 0px 3px 3px #00000050;
  box-shadow: 0px 3px 3px #00000050;
  border-radius: 1rem !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  .card-cases {
    width: 200px;
    height: 160px;
    -webkit-box-shadow: 0px 3px 3px #00000050;
    box-shadow: 0px 3px 3px #00000050;
    border-radius: 1rem !important;
    cursor: pointer;
  }
}

.card-cases-inactive {
  width: 220px;
  height: 90px;
  /* margin: 20px; */
  -webkit-box-shadow: 0px 3px 3px #00000050;
  box-shadow: 0px 3px 3px #00000050;
  border-radius: 1rem !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  .card-cases-inactive {
    width: 200px;
    height: 90px;
    -webkit-box-shadow: 0px 3px 3px #00000050;
    box-shadow: 0px 3px 3px #00000050;
    border-radius: 1rem !important;
    cursor: pointer;
  }
}
.card-cases {
  width: 220px;
  height: 160px;
  /* margin: 20px; */
  -webkit-box-shadow: 0px 3px 3px #00000050;
  box-shadow: 0px 3px 3px #00000050;
  border-radius: 1rem !important;
  cursor: pointer;
}

@media (max-width: 992px) {
  .card-cases {
    width: 200px;
    height: 160px;
    -webkit-box-shadow: 0px 3px 3px #00000050;
    box-shadow: 0px 3px 3px #00000050;
    border-radius: 1rem !important;
    cursor: pointer;
  }
}

.card-people {
  width: 300px;
  height: 100px;
  border-radius: 1rem !important;
}

@media (max-width: 992px) {
  .card-people {
    width: 300px;
    height: 100px;
    border-radius: 1rem !important;
  }
}

.card-checkin {
  width: 400px;
  height: 100px;
  border-radius: 1rem !important;
}

/* @media (max-width: 1200px) {
  .card-checkin {
    width: 300px;
    height: 100px;
    border-radius: 1rem !important;
  } 
}

@media (max-width: 992px) {
  .card-checkin {
    width: 400px;
    height: 100px;
    border-radius: 1rem !important;
  } 
} */

.card-p {
  position: absolute;
  top: 10px;
  font-size: smaller;
}

.card-alert-text {
  font-size: smaller;
}

.card-danger {
  border-color: #dc664b !important;
}

.card-household {
  /* max-width: 240px !important; */
  min-width: 180px !important;
  /* height: clamp(200px, 100%, 300px) !important; */
  min-height: 200px !important;
  cursor: pointer;
}

.card-household-condensed {
  min-width: 180px !important;
  min-height: 110px !important;
}

.checkbox-alignment {
  margin: auto 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.date-label {
  z-index: 1;
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.date-picker {
  padding-top: 1.6rem !important;
  padding-bottom: 0.4rem !important;
}

.decorated option:checked {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

.div-main {
  position: relative;
  top: 100px; /* Position the div 100px from the top */
}

.div-main-menu {
  position: relative;
  top: 200px; /* Position the div 100px from the top */
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.fade-in-out {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-in-out.hidden {
  opacity: 0.25;
}

.fa-nav {
  width: 100%;
}

.first-letter {
  text-transform: capitalize;
}

.footnote {
  text-align: right;
}

.form-control:focus {
  border-color: #da7e39 !important;
  box-shadow: 0 0 0 0.25rem rgba(249, 98, 60, 0.5) !important;
  z-index: 0 !important;
}

.form-select:focus {
  border-color: #da7e39 !important;
  box-shadow: 0 0 0 0.25rem rgba(249, 98, 60, 0.5) !important;
}

.no-caret {
  appearance: none !important; /* Removes the caret */
  -webkit-appearance: none !important; /* Removes the caret for Safari */
  -moz-appearance: none !important; /* Removes the caret for Firefox */
  background-image: none !important; /* Ensures no background arrow is present */
}

.form-check-input:focus {
  border-color: #da7e39 !important;
  box-shadow: 0 0 0 0.25rem rgba(249, 98, 60, 0.5) !important;
}

.form-check-input:checked {
  background-color: #da7e39 !important;
  border-color: #da7e39 !important;
}

.form-floating-label-textarea {
  padding: 2rem 0.75rem 0rem 0.75rem;
}

.image-logo {
  height: 200px;
  width: auto !important;
  object-fit: contain;
}

.image-profile {
  height: 200px !important;
  width: auto !important;
  object-fit: contain;
}

.image-people {
  height: 70px !important;
  width: 70px !important;
  max-width: 70px !important;
  object-fit: contain;
  border-radius: 0.5rem;
}

/*Spinner CSS*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-floating > label {
  opacity: 0.65 !important;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !important;
}

/*well being assessment likert scale */
.list-group-item.active {
  z-index: 1 !important;
  background-color: #da7e39 !important;
  border-color: #da7e39 !important;
}

.message {
  background-color: transparent !important;
  border: none !important;
}

.message-header {
  flex: 0 1 40px;
  height: 60px;
}

.message-body {
  flex: 0 1 auto;
  height: 100%;
  overflow: auto;
}

.message-box {
  display: flex;
  flex-flow: column;
  height: calc(100vh - 280px);
}

.message-container {
  flex-grow: 3 !important;
  min-width: 200px !important;
  flex: 1 1 auto;
}

.message-content {
  flex: 1 1 auto;
  height: calc(100vh - 240px);
}

.message-footer {
  flex: 0 1 40px;
  height: 100px;
  border-radius: 5px;
}

.message-item {
  font-size: small;
}

.message-list {
  flex-grow: 1 !important;
  min-width: 200px !important;
  max-width: 250px !important;
  flex: 1 1 auto;
}

.message-input {
  height: 40px !important;
}

.message-input:focus {
  height: 40px !important;
  border: none !important;
  border-color: none !important;
  box-shadow: none !important;
}

.mycase-menu-sticky {
  position: sticky !important;
  top: 80px !important;
  z-index: 0 !important;
}

.mycase-menu-open-sticky {
  position: sticky !important;
  top: 190px !important;
  z-index: 0 !important;
}

.mycase-date-sticky {
  top: 560px !important;
}

.mycase-date-open-sticky {
  top: 670px !important;
}

.nav {
  background-color: #ffffff;
  color: #000000;
  align-items: center;
  justify-content: center;
  position: sticky !important;
  top: 0;
  z-index: 3;
}

.nav-action {
  background-color: #ffffff;
  color: #000000;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100px;
  -webkit-box-shadow: 0px 3px 3px #00000050;
  box-shadow: 0px 3px 3px #00000050;
}

.nav-base {
  background-color: #5a7082;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  position: fixed !important;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 3;
  height: 20px;
}

.nav-header {
  background-color: #5d82a0;
  height: 60px;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
}

.nav-item {
  width: 100%;
  /* color: #ffffff;
  background-color: #7D9158; */
}

.nav-link {
  color: #ffffff !important;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nav-static {
  -webkit-box-shadow: 0px 3px 3px #00000050;
  box-shadow: 0px 3px 3px #00000050;
}

.nav-pills {
  z-index: 0;
}

.nav-vertical {
  background-color: #ffffff !important;
  color: #5a7082 !important;
  width: 100% !important;
  height: 40px;
  padding-top: 7px !important;
}

.nav-vertical-active {
  background-color: #5a7082 !important;
  color: #ffffff !important;
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 7px !important;
}

.navbar-menu {
  z-index: 4;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.password-invalid {
  color: #dc664b !important;
}

.password-valid {
  color: #7d9158 !important;
}

.phone-input {
  padding-left: 3rem !important;
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.react-datepicker__month-container {
  z-index: 2;
}

.receiver {
  display: inline-block;
  justify-content: flex-start;
  background-color: lightgray;
  color: #5a7082 !important;
  border-radius: 5px 25px 25px 25px;
  padding: 10px 15px 10px 10px;
  height: auto;
  font-size: 10pt;
}

.receiver-text {
  font-size: 9pt;
}

.receiver-list {
  padding-bottom: 4px !important;
  padding-right: 80px;
}

.search {
  max-width: 1110px;
  margin: 0px 10px 0px 10px;
}

/* continuum items */
.select-item {
  width: 20% !important;
  font-size: small;
}

.sender {
  display: flex;
  justify-content: flex-end;
  background-color: #7d9158;
  color: #ffffff !important;
  border-radius: 25px 5px 25px 25px;
  padding: 10px 10px 10px 15px;
  height: auto;
  font-size: 10pt;
}

.sender-list {
  padding-right: 8px !important;
  padding-left: 64px !important;
  padding-bottom: 4px !important;
}

.sender-row {
  display: flex;
  flex-direction: row-reverse;
}

.sender-text {
  font-size: 9pt;
}

.spinner-button {
  background-color: #fff !important;
  color: #000 !important;
  height: 50px;
  width: 140px;
  border-color: #fff !important;
}

.spinner-button-main {
  margin: -25px -70px;
  position: relative;
  top: 50px;
  left: 50%;
}

.spinner-small {
  width: 1rem !important;
  height: 1rem !important;
}

.smaller-text {
  font-size: 10px;
  margin: 0;
}

.small-text {
  font-size: 12px;
  margin: 0;
}

.medium-text {
  font-size: 14px;
  margin: 0;
}

.stack-float {
  float: right !important;
}

.successmsg {
  color: #da7e39;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.tabs-user .nav-link {
  background-color: #ffffff !important;
  color: #5a7082 !important;
}

.tabs-user .nav-link.active {
  background-color: #5a7082 !important;
  color: #ffffff !important;
}

.toast-location {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 100;
}

.toast-location-bottom {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 100;
}

.btn-close {
  color: #ffffff !important;
}

.js .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  /*padding: 0.625rem 1.25rem;
  /* 10px 20px */
}

.no-js .inputfile + label {
  display: none;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label svg {
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
  margin-top: -0.25em;
  margin-right: 0.25em;
}

.inputfile-1 + label {
  color: #ffffff !important;
  background-color: #5a7082;
}
